import Home from "../components/home/Home";
import NormaAurora from "../pages/NormaAurora";
import Melting from "../pages/Melting";
import TechInFashion from "../pages/TechInFashion";
import Textiles from "../pages/Textiles";
import Tanzite from "../pages/Tanzite";
import Illustrations from "../pages/Illustrations";
import CustomWork from "../pages/CustomWork";

export const routes = [
    {
        name: "Silvermist Design", 
        path: "/",
        component: <Home />,
        exact: true,
    },
    {
        name: "Norma Aurora", 
        path: "/norma-aurora",
        component: <NormaAurora />,
    },
    {
        name: "Melting", 
        path: "/melting",
        component: <Melting />,
    },
    {
        name: "Tech in Fashion", 
        path: "/tech-in-fashion",
        component: <TechInFashion />,
    },
    {
        name: "Textiles", 
        path: "/textiles",
        component: <Textiles />,
    },
    {
        name: "Tanzanite", 
        path: "/tanzanite",
        component: <Tanzite />,
    },
    {
        name: "Illustrations", 
        path: "/illustrations",
        component: <Illustrations />,
    },
    {
        name: "Custom Work",
        path: "/custom-work",
        component: <CustomWork />,
    }
]