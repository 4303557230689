import { useState, useCallback } from "react";
import ImageViewer from 'react-simple-image-viewer';
import { MISC_WORK } from "../images/misc-work/imageObjects";
import ProgressiveImg from "../components/progressiveimg/ProgressiveImg";

export default function Haley() {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [currentSummerImage, setSummerCurrentImage] = useState(0);
    const [isSummerViewerOpen, setIsSummerViewerOpen] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
      }, []);

    const openSummerImageViewer = useCallback((index) => {
        setSummerCurrentImage(index);
        setIsSummerViewerOpen(true);
      }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);

        setSummerCurrentImage(0);
        setIsSummerViewerOpen(false);
      };
    return (
        <> 
            <h2 className="light-header">Haley</h2>
            <div className="haley spaced-line image-top-container">
            {MISC_WORK.haley.fantasyDress.map((src, index) => (
                <ProgressiveImg 
                src={ src }
                onClick={ () => openImageViewer(index) }
                className="max-size"
                key={ index }
                style={{ margin: '9px' }}
                alt=''
                />
            ))}
            </div>
            {isViewerOpen && (
                <ImageViewer
                src={MISC_WORK.haley.fantasyDress }
                currentIndex={ currentImage }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                />
            )}
            <div className="image-top-container haley-two">
            {MISC_WORK.haley.summerDress.map((src, index) => (
                <ProgressiveImg 
                src={ src }
                onClick={ () => openSummerImageViewer(index) }
                className="max-size"
                key={ index }
                style={{ margin: '9px' }}
                alt=''
                />
            ))}
            </div>
            {isSummerViewerOpen && (
                <ImageViewer
                src={MISC_WORK.haley.summerDress}
                currentIndex={ currentSummerImage }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                />
            )}

        </>
    )
}