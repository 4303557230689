import React, { useEffect, useState } from 'react'
import upArrow from "../images/upArrow.webp";


export default function GoTopButton () {
  const [ showGoTop, setShowGoTop ] = useState( false )

  const handleVisibleButton = () => {
      setShowGoTop( window.pageYOffset > 50 )
  }

  const handleScrollUp = () => {
      window.scrollTo( { left: 0, top: 0, behavior: 'smooth' } )
  }

  useEffect( () => {
      window.addEventListener( 'scroll', handleVisibleButton )
  }, [] )

  return (
      <div className={ showGoTop ? '' : 'goTopHidden' } onClick={ handleScrollUp }>
          <button type={'button'} className='goTop'>
              <img src={upArrow} alt='Up Arrow'/>
          </button>
      </div>
  )
};