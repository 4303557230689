import { useState, useCallback } from "react";
import UseDocumentTitle from "../functions/useDocumentTitle";
import NA from "../images/norma-aurora/sc-name.webp";
import NAMOOD from "../images/norma-aurora/sc-mood.webp";
import NAPRINT from "../images/norma-aurora/The-Print.webp";
import NADESIGN from "../images/norma-aurora/Norma-Aurora.webp";
import { AURORA_CAROUSEL } from "../images/norma-aurora/imageObjects";
import Carousel from 'react-bootstrap/Carousel';
import ImageViewer from 'react-simple-image-viewer';
import ProgressiveImg from "../components/progressiveimg/ProgressiveImg";


const PHOTOS = [NAPRINT, NAMOOD, NADESIGN]

export default function NormaAurora() {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
      }, []);
    
      const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
      };
    return (
        <> 
            <UseDocumentTitle title="Norma Aurora" />
            <div className="page-header">
                <ProgressiveImg src={NA} alt="Norma Aurora Title" />
            </div>
            <div className="image-top-container borderline">
            {PHOTOS.map((src, index) => (
                <ProgressiveImg 
                src={ src }
                className="max-size"
                onClick={ () => openImageViewer(index) }
                key={ index }
                style={{ margin: '2px' }}
                alt=''
                />
            ))}
            {isViewerOpen && (
                <ImageViewer
                src={ PHOTOS }
                currentIndex={ currentImage }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                />
            )}
            </div>
            <Carousel fade={true} indicators={false}>
                {AURORA_CAROUSEL.map((each, index) => (
                    <Carousel.Item key={each.image + index}>
                        <ProgressiveImg 
                            src={each.image}
                            alt={each.alt} width={625}
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
        </>
    )
}