import GROUP_PHOTO from "./runway/NA1.webp";
import NA2 from "./runway/NA2.webp";
import NA2v1 from "./runway/NA3-_1_.webp";
import NA2v2 from "./runway/NA3-_2_.webp";
import NA2v3 from "./runway/NA3-_3_.webp";
import NA3 from "./runway/NA4-_1_.webp";
import NA3v1 from "./runway/NA4-_2_.webp";
import NA3v2 from "./runway/NA4-_3_.webp";
import NA3v3 from "./runway/NA4-_4_.webp";
import NA3v4 from "./runway/NA4-_5_.webp";
import NA4 from "./runway/NA5-_1_.webp";
import NA4v1 from "./runway/NA5-_2_.webp";
import NA4v2 from "./runway/NA5-_3_.webp";
import NA4v3 from "./runway/NA5-_4_.webp";
import NA4v4 from "./runway/NA5-_5_.webp";
import NA45 from "./runway/NA6-_3_.webp";
import NA5 from "./runway/NA6-_1_.webp";
import NA5v1 from "./runway/NA6-_5_.webp";
import NA5v2 from "./runway/NA6-_6_.webp";
import NA5v3 from "./runway/NA6-_7_.webp";
import NA5v4 from "./runway/NA6-_8_.webp";
import NA5v5 from "./runway/NA6-_9_.webp";
import NA5v6 from "./runway/NA6-_10_.webp";
import NA5v7 from "./runway/NA6-_11_.webp";
import NA6 from "./runway/NA7-_1_.webp";
import NA6v1 from "./runway/NA7-_2_.webp";
import NA6v2 from "./runway/NA7-_3_.webp";
import NA6v3 from "./runway/NA7-_4_.webp";
import NA6v4 from "./runway/NA7-_5_.webp";
import NA6v5 from "./runway/NA7-_6_.webp";
import NA6v6 from "./runway/NA7.webp";

export const AURORA_CAROUSEL = [
    {
        image: GROUP_PHOTO,
        alt: ""
    },
    {
        image: NA2,
        alt: "custom dress"
    },
    {
        image: NA2v1,
        alt: "custom dress"
    },
    {
        image: NA2v2,
        alt: "custom dress"
    },
    {
        image: NA2v3,
        alt: "custom dress"
    },
    {
        image: NA3,
        alt: "custom dress"
    },
    {
        image: NA3v1,
        alt: "custom dress"
    },
    {
        image: NA3v2,
        alt: "custom dress"
    },
    {
        image: NA3v3,
        alt: "custom dress"
    },
    {
        image: NA3v4,
        alt: "custom dress"
    },
    {
        image: NA4,
        alt: "custom dress"
    },
    {
        image: NA4v1,
        alt: "custom dress"
    },
    {
        image: NA4v2,
        alt: "custom dress"
    },
    {
        image: NA4v3,
        alt: "custom dress"
    },
    {
        image: NA4v4,
        alt: "custom dress"
    },
    {
        image: NA45,
        alt: "custom dress"
    },
    {
        image: NA5,
        alt: "custom dress"
    },
    {
        image: NA5v1,
        alt: "custom dress"
    },
    {
        image: NA5v2,
        alt: "custom dress"
    },
    {
        image: NA5v3,
        alt: "custom dress"
    },
    {
        image: NA5v4,
        alt: "custom dress"
    },
    {
        image: NA5v5,
        alt: "custom dress"
    },
    {
        image: NA5v6,
        alt: "custom dress"
    },
    {
        image: NA5v7,
        alt: "custom dress"
    },
    {
        image: NA6,
        alt: "custom dress"
    },
    {
        image: NA6v1,
        alt: "custom dress"
    },
    {
        image: NA6v2,
        alt: "custom dress"
    },
    {
        image: NA6v3,
        alt: "custom dress"
    },
    {
        image: NA6v4,
        alt: "custom dress"
    },
    {
        image: NA6v5,
        alt: "custom dress"
    },
    {
        image: NA6v6,
        alt: "custom dress"
    },
]
