import { useState, useCallback } from "react";
import UseDocumentTitle from "../functions/useDocumentTitle";
import TITLE from "../images/melting/name.webp";
import { MELTING_PHOTOS } from "../images/melting/imageObjects";
import ImageViewer from 'react-simple-image-viewer';
import ProgressiveImg from "../components/progressiveimg/ProgressiveImg";

export default function NormaAurora() {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [currentPrintImage, setCurrentPrintImage] = useState(0);
    const [isViewerPrintOpen, setIsViewerPrintOpen] = useState(false);
    const [currentOutfitImage, setCurrentOutfitImage] = useState(0);
    const [isViewerOutfitOpen, setIsViewerOutfitOpen] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
      }, []);

    const openImageViewerPrints = useCallback((index) => {
        setCurrentPrintImage(index);
        setIsViewerPrintOpen(true);
      }, []);

    const openImageViewerOutFits = useCallback((index) => {
        setCurrentOutfitImage(index);
        setIsViewerOutfitOpen(true);
      }, []);
    
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
        
        setCurrentPrintImage(0);
        setIsViewerPrintOpen(false);
        
        setCurrentOutfitImage(0);
        setIsViewerOutfitOpen(false);
        
      };

    return (
        <> 
            <UseDocumentTitle title="Melting" />
            <div className="page-header">
                <ProgressiveImg src={TITLE} alt="Melting Title"/>
            </div>

            <div className="image-top-container borderline melting-concept">
            {MELTING_PHOTOS.meltingConcepts.map((src, index) => (
                <ProgressiveImg 
                src={ src }
                onClick={ () => openImageViewer(index) }
                className="max-size max-height"
                key={ index }
                style={{ margin: '2px' }}
                alt=''
                />
            ))}
            </div>
            {isViewerOpen && (
                <ImageViewer
                src={MELTING_PHOTOS.meltingConcepts }
                currentIndex={ currentImage }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                />
            )}
            {/* Image Viewer Separation */}
            <div className="image-top-container borderline standard-img-size">
                <h2 className="light-header">Acid Melt</h2>
                <ProgressiveImg 
                    onClick={() => openImageViewerPrints(0)} 
                    src={ MELTING_PHOTOS.designBoard[0] }
                    className="max-size max-height"
                    alt="Print"
                />
                <ProgressiveImg 
                    onClick={() => openImageViewerPrints(1)} 
                    src={MELTING_PHOTOS.designBoard[1]}
                    className="max-size max-height"
                    alt="Design Board"
                />
            </div>
            {isViewerPrintOpen && (
                <ImageViewer
                src={MELTING_PHOTOS.designBoard }
                currentIndex={ currentPrintImage }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                />
            )}

            <div className="col-3">
            {MELTING_PHOTOS.models.map((src, index) => (
                <ProgressiveImg 
                src={ src }
                onClick={ () => openImageViewerOutFits(index) }
                className="max-size"
                key={ index }
                style={{ margin: '9px' }}
                alt=''
                />
            ))}
            </div>
            {isViewerOutfitOpen && (
                <ImageViewer
                src={MELTING_PHOTOS.models }
                currentIndex={ currentOutfitImage }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                />
            )}
        </>
    )
}