import { SocialIcon } from "react-social-icons";

const SocialMedia = () => {
  return (
    <>
    <div style={{ marginTop: "2%" }}>
      <SocialIcon
        url="https://www.facebook.com/SilvermistDesign"
        network="facebook"
        className='socialLink'
        style={{ margin: "0 1.5% 0 0"}}
        />
      <SocialIcon
        url="https://www.instagram.com/silvermistdesign"
        network="instagram"
        className='socialLink'
        />
    </div>
    </>
  );
};

export default SocialMedia;