import CONCEPT from "./concept-_1_.webp";
import CONCEPTv1 from "./concept-_2_.webp";
import CONCEPTv2 from "./concept-_3_.webp";

import MODEL from "./model-_1_.webp";
import MODELv1 from "./model-_2_.webp";
import MODELv2 from "./model-_3_.webp";
import MODELv4 from "./model-_5_.webp";
import MODELv5 from "./model-_6_.webp";
import MODELv6 from "./model-_7_.webp";

import MPRINT from "./print.webp";
import MMELTING from "./melting.webp";

export const MELTING_PHOTOS = {
    "meltingConcepts": [
        CONCEPT, CONCEPTv1, CONCEPTv2
    ],
    "models" : [
        MODELv1, MODELv4, MODELv2, MODELv6, MODELv5, MODEL
    ],
    "designBoard" : [
        MMELTING,
        MPRINT
    ]
}