import { useRef, useEffect } from 'react'

export default function UseDocumentTitle(props, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = `${props.title} | Silvermist Design`;
  }, [props]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = `${defaultTitle.current} | Silvermist Design`;
    }
  }, [])
}
