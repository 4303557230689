import MirandaImage from '../../images/silvermist-design-intro.webp';
import ContactForm from '../contactform/ContactForm';
import UseDocumentTitle from '../../functions/useDocumentTitle';
import ProgressiveImg from '../progressiveimg/ProgressiveImg';
import NORMA from "../../images/Norma.webp";
//Random line to test

export default function Home() {

    const bio = `
    Hello, my name is Miranda. 
    I'm the owner and head designer at Silvermist Design. 
    I'm here to help alter your garments or make your custom designs come to life. 
    Check out the tabs to see what I can do.
    `

    return (
        <>
            <UseDocumentTitle title="Home" />
            <a id="crowdfundr" href="http://crowdfundr.com/SilvermistdesignNorma" target="_blank" rel="noopener noreferrer">
                <ProgressiveImg src={NORMA} />
            </a>
            <h2 className="title light underline"><i>Fashion Designer, Product Development, and Alterations</i></h2>
            <div className="card mb-3" id="info-card">
                <div className="row g-0">
                    <div className="col-md-4">
                    <ProgressiveImg src={MirandaImage} className="img-fluid rounded-start" />
                    </div>
                    <div className="col-md-8">
                    <div className="card-body">
                        <p className="card-text">{bio}</p>
                    </div>
                    </div>
                </div>
            </div>

            <ContactForm />
            
        </>
    )
}