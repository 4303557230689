import UseDocumentTitle from "../functions/useDocumentTitle";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Warhorse from './Warhorse';
import Haley from './Haley';
import WeddingDress from './WeddingDress';
import MoreFun from './MoreFun';
import ProgressiveImg from "../components/progressiveimg/ProgressiveImg";
import TITLE from "../images/misc-work/name.webp";


export default function CustomWork () {

    return (
        <>
        <UseDocumentTitle title="Custom Work" />
        <div className="page-header">
        <ProgressiveImg src={TITLE} />
        </div>
        <Tabs id="customWork">
            <TabList>
                <Tab>Warhorse</Tab>
                <Tab>Haley</Tab>
                <Tab>Wedding Dresses</Tab>
                <Tab>More Fun</Tab>
            </TabList>
            <TabPanel>
                <Warhorse />
            </TabPanel>
            <TabPanel>
                <Haley />
            </TabPanel>
            <TabPanel>
                <WeddingDress />
            </TabPanel>
            <TabPanel>
                <MoreFun />
            </TabPanel>
        </Tabs>
        </>
    )
}