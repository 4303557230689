import { Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import './App.css';
import './styles/main.css';
import { routes } from "./routes/routes";
import { NavLink } from 'react-router-dom';
import Footer from './components/footer/Footer';
import Navigation from './components/navigation/Navigation';
import GoTopButton from './functions/GoTopButton';
import LOGO from "./images/logo.webp";
import PLACEHOLDER from "./images/placeholderImg.png"
import ProgressiveImg from "./components/progressiveimg/ProgressiveImg";


export default function App() {
  const [windowDimension, setWindowDimension] = useState(null);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension <= 768;
  return (
    <div className="App" id="outer-container">
      <GoTopButton />
      <div className="bg-animation" style={{'zIndex': '-1'}}>
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
        <div id="stars4"></div>
      </div>
      <Navigation />
      <nav id="web-content">
        { isMobile ?
        <div id="site-title">
          <NavLink to="/">
            <ProgressiveImg id="navLogo" src={LOGO} placeholder={PLACEHOLDER}/>
          </NavLink>
        </div> : null }
        <Routes>
          {routes &&
            routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                element={route.component}
              />
            ))}
        </Routes>
      </nav>
      <Footer />
    </div>
  );
}