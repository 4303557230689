import { useState, useCallback } from "react";
import UseDocumentTitle from "../functions/useDocumentTitle";
import TITLE from "../images/textiles/name.webp"
import { TEXTILES } from "../images/textiles/imageObjects";
import ImageViewer from 'react-simple-image-viewer';
import ProgressiveImg from "../components/progressiveimg/ProgressiveImg";


const TEXTILES_PHOTOS = [
    TEXTILES[0].photo, TEXTILES[1].photo, TEXTILES[2].photo,
    TEXTILES[3].photo, TEXTILES[4].photo
]

export default function Textiles() {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
      }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
      };
    return (
        <> 
            <UseDocumentTitle title="Textiles" />
            <div className="page-header">
                <ProgressiveImg src={TITLE} alt="Textiles Title"/>
            </div>
            <div className="image-top-container print-selection">
                {TEXTILES.map((src, index) => (
                    <div>
                    <h2 className="light-header">{src.name}</h2>
                    {src.text ? <p className="light"><i>{src.text}</i></p> : null}
                    <ProgressiveImg 
                    onClick={ () => openImageViewer(index) }
                    className="rounded-img max-size max-height squared"
                    style={{ margin: '2px' }}
                    alt=''
                    src={src.photo} 
                    />
                </div>
            ))}
            </div>
            {isViewerOpen && (
                <ImageViewer
                src={TEXTILES_PHOTOS}
                currentIndex={ currentImage }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                />
            )}
        </>
    )
}