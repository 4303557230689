import { useState, useEffect } from "react";
import PLACEHOLDER from "../../images/placeholderImg.webp";

export default function ProgressiveImg ({ src, ...props }) {
  const [imgSrc, setImgSrc] = useState(PLACEHOLDER || src);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);

  const customClass =
    PLACEHOLDER && imgSrc === PLACEHOLDER ? "loading" : "loaded";

  return (
    <>
    {customClass.includes("loading") ?
    <div className="loader"></div>
    :
      <img
        {...{ src: imgSrc, ...props }}
        alt={props.alt || ""}
        className={`image ${customClass} ${props.className}`}
        />
    }
      </>
  );
}