import { useState, useCallback } from "react";
import ImageViewer from 'react-simple-image-viewer';
import { MISC_WORK } from "../images/misc-work/imageObjects";
import ProgressiveImg from "../components/progressiveimg/ProgressiveImg";



export default function Warhorse() {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
      }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
      };
    return (
        <> 
            <h2 className="light title">Warhorse</h2>
            <div className="image-top-container ">
            {MISC_WORK.warhorse.map((src, index) => (
                <ProgressiveImg 
                src={ src }
                onClick={ () => openImageViewer(index) }
                className="max-size max-height"
                key={ index }
                style={{ margin: '2px' }}
                alt=''
                />
            ))}
            </div>
            {isViewerOpen && (
                <ImageViewer
                src={MISC_WORK.warhorse}
                currentIndex={ currentImage }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                />
            )}
        </>
    )
}