import { useState, useCallback } from "react";
import { MISC_WORK } from "../images/misc-work/imageObjects";
import ImageViewer from 'react-simple-image-viewer';
import ProgressiveImg from "../components/progressiveimg/ProgressiveImg";

export default function WeddingDress() {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [currentHayImage, setCurrentHayImage] = useState(0);
    const [isViewerHayOpen, setIsViewerHayOpen] = useState(false);
    const [currentMillerImage, setCurrentMillerImage] = useState(0);
    const [isViewerMillerOpen, setIsViewerMillerOpen] = useState(false);

    const openImageViewer = useCallback((name, index) => {
        if (name.includes("Cross")) {
            setCurrentImage(index);
            setIsViewerOpen(true);
        }

        if (name.includes("Hay")) {
            setCurrentHayImage(index);
            setIsViewerHayOpen(true);
        }

        if (name.includes("Miller")) {
            setCurrentMillerImage(index);
            setIsViewerMillerOpen(true);
        }
      }, []);

    
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
        
        setCurrentHayImage(0);
        setIsViewerHayOpen(false);
        
        setCurrentMillerImage(0);
        setIsViewerMillerOpen(false);
        
      };

    return (
        <> 
            <h2 className="light-header">Wedding Dresses</h2>
            <div className="image-top-container ">
                {MISC_WORK.weddingDresses.map((src, indexOne) => (
                    <div key={indexOne} className="wedding">
                        <h2 className="light-header">{src.weddingName}</h2>
                        <p className="light">{src.information}</p>
                        <div className="wedding-images">
                            {src.photos.map((srcPhoto, index) => (
                                <ProgressiveImg 
                                src={ srcPhoto }
                                onClick={ () => openImageViewer(src.weddingName, index) }
                                className="max-size max-height"
                                key={ index }
                                style={{ margin: '2px' }}
                                alt=''
                                />
                            ))}   
                        </div>
                    </div>
                ))}
            </div>
            {isViewerOpen && (
                <ImageViewer
                src={MISC_WORK.weddingDresses[0].photos}
                currentIndex={ currentImage }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                />
                )}
            {isViewerHayOpen && (
                <ImageViewer
                src={MISC_WORK.weddingDresses[1].photos}
                currentIndex={ currentHayImage }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                />
                )}
            {isViewerMillerOpen && (
                <ImageViewer
                src={MISC_WORK.weddingDresses[2].photos}
                currentIndex={ currentMillerImage }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                />
                )}
        </>
    )
}