import { useState, useCallback } from "react";
import ImageViewer from 'react-simple-image-viewer';
import UseDocumentTitle from "../functions/useDocumentTitle";
import TITLE from "../images/tanzanite/tanzite.webp";
import { TANZITE as MISC_WORK } from "../images/tanzanite/imageObjects";
import ProgressiveImg from "../components/progressiveimg/ProgressiveImg";

export default function Tanzite() {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
      }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
      };
    return (
        <> 
            <UseDocumentTitle title="Tanzite" />
            <div className="page-header">
                <ProgressiveImg src={TITLE} alt="Tanzanite Title" />
            </div>
            <div className="image-top-container ">
                {MISC_WORK.map((src, index) => (
                    <ProgressiveImg 
                    src={ src }
                    onClick={ () => openImageViewer(index) }
                    className="max-size"
                    key={ index }
                    style={{ margin: '2px' }}
                    alt=''
                    />
                ))}
            </div>
            {isViewerOpen && (
                <ImageViewer
                src={MISC_WORK}
                currentIndex={ currentImage }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                />
            )}
        </>
    )
}