import SAKURA from "./sakura.webp";
import HARAJUKU from "./harajuku.webp";
import FULLBLOOM from "./fullbloom.webp";

export const ILLUSTRATIONS = [
    {
        title: "Harajuku",
        photo: HARAJUKU
    },
    {
        title: "Full Bloom",
        photo: FULLBLOOM
    },
    {
        title: "Sakura",
        photo: SAKURA
    }
]