import SUCCULENTS from "./succulents.webp";
import LVL10 from "./lvl10.webp";
import CRYSTALBOUQUET from "./crystalBouquet.webp";
import MELT from "../melting/print.webp";
import AURORA from "../norma-aurora/The-Print.webp";

export const TEXTILES = [
    {
        "photo": SUCCULENTS,
        "name": "Succulents"
    },
    {
        "photo": CRYSTALBOUQUET,
        "name": "Crystal Bouquet"
    },
    {
        "photo": MELT,
        "name": "Acid Melt"
    },
    {
        "photo": AURORA,
        "name": "Aurora"
    },
    {
        photo: LVL10,
        name: "LVL 10",
        text: "*This print was inspired and based on Ariana Oraner's Critical role character art."
    }
]