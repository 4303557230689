import CROSS from './wedding/cross-_1_.webp';
import CROSSv1 from './wedding/cross-_2_.webp';
import CROSSv2 from './wedding/cross-_3_.webp';

import HAY from './wedding/hunter.webp';
import HAYv1 from './wedding/hunter-_1_.webp';
import HAYv2 from './wedding/hunter-_2_.webp';
import HAYv3 from './wedding/hunter-_3_.webp';
import HAYv4 from './wedding/hunter-_4_.webp';

import MILLER from "./wedding/miller-_1_.webp";
import MILLERv1 from "./wedding/miller-_5_.webp";
import MILLERv2 from "./wedding/miller-_2_.webp";
import MILLERv3 from "./wedding/miller-_3_.webp";
import MILLERv4 from "./wedding/miller-_4_.webp";

import WARHORSE from "./warhorse/warhorse-_1_.webp";
import WARHORSEv1 from "./warhorse/warhorse-_2_.webp";

import HALEYFANTASY from "./haley/dress-_2_.webp";
import HALEYFANTASYv1 from "./haley/dress-_1_.webp";
import HALEYFANTASYv2 from "./haley/dress-_3_.webp";
import HALEYFANTASYv3 from "./haley/dressanddog-_1_.webp";
import HALEYFANTASYv4 from "./haley/dressanddog-_2_.webp";
import HALEYFANTASYv5 from "./haley/dressanddog-_3_.webp";
import HALEYSUMMER from "./haley/dogprint-_1_.webp";
import HALEYSUMMERv1 from "./haley/dogprint-_2_.webp";
import HALEYSUMMERv2 from "./haley/dogprint-_3_.webp";

import BLUEDRESS from "./etc/bluedress-_1_.webp";
import BLUEDRESSv1 from "./etc/bluedress-_2_.webp";
import FLOWER from "./etc/flower-_1_.webp";
import FLOWERv1 from "./etc/flower-_2_.webp";
import CAUTION from "./etc/caution-_1_.webp";
import CAUTIONv1 from "./etc/caution-_2_.webp";
import BLUESUIT from "./etc/bluesuit-_1_.webp";
import BLUESUITv1 from "./etc/bluesuit-_2_.webp";
import BLUESUITv2 from "./etc/bluesuit-_3_.webp";

export const MISC_WORK = {
    "warhorse": [
        WARHORSE, WARHORSEv1
    ],
    "etc" : [
        {
            name: "suit",
            description: "A Silk Dupioni suit that has a smocking with beads",
            photos: 
            [BLUESUIT, BLUESUITv2, BLUESUITv1],
        },
        {
            name: "random",
            description: "A challenge to make dresses out of abnormal materials",
            photos:
            [FLOWER, FLOWERv1, CAUTION, CAUTIONv1],
        },
        {
            name: "dye",
            description: "A dye worked dress.",
            photos:
            [BLUEDRESS, BLUEDRESSv1],
        }
    ],
    "haley": {
        fantasyDress: [
            HALEYFANTASYv1, HALEYFANTASY, HALEYFANTASYv2, HALEYFANTASYv3, HALEYFANTASYv5, HALEYFANTASYv4
        ],
        summerDress: [
            HALEYSUMMER, HALEYSUMMERv2, HALEYSUMMERv1
        ]
    },
    "weddingDresses": [
        {
            weddingName: "The Crosses",
            information: "I had mostly done all the fun extras that went on the dress. I made the sleeve caps removable. The cape was made from her mothers' veil that I sewed the red lace onto and while she still needed something blue. I gave her the idea of adding the first letter of their pet's names behind the red lace. She loved the idea and so I made it happen.",
            photos: [
                CROSSv1, CROSSv2, CROSS
            ]
        },
        {
            weddingName: "The Hays",
            information: "I did a number of changes to this dress; we first started by removing the length in the boning and after that this dress got taken in about 4 sizes. I replaced the shelves that came on the dress and sewed on the flowy black ones seen in the pictures. I added horse hair at the hem and made the small belt that went on the waist.",
            photos: [
                HAY, HAYv4, HAYv3, HAYv2, HAYv1
            ]
        },
        {
            weddingName: "The Millers",
            information: "Both the bride and groom needed custom work done to their outfits before the big day. The groom had his pants tailored and had a color strip added to the suit. As for the bride I closed the front of the dress, hemed, bustled, and made a custom veil.",
            photos: [
                MILLER, MILLERv2, MILLERv1, MILLERv3, MILLERv4
            ]
        }
    ]
}