import { useState, useCallback } from "react";
import { MISC_WORK } from "../images/misc-work/imageObjects";
import ImageViewer from 'react-simple-image-viewer';
import ProgressiveImg from "../components/progressiveimg/ProgressiveImg";

export default function MoreFun() {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [currentImg2, setCurrentImg2] = useState(0);
    const [isViewerOpen2, setIsViewerOpen2] = useState(false);
    const [currentImg3, setCurrentImg3] = useState(0);
    const [isViewerOpen3, setIsViewerOpen3] = useState(false);

    const openImageViewer = useCallback((name, index) => {
        if (name === "suit") {
            setCurrentImage(index);
            setIsViewerOpen(true);
        }

        if (name === "random") {
            setCurrentImg2(index);
            setIsViewerOpen2(true);
        }

        if (name === "dye") {
            setCurrentImg3(index);
            setIsViewerOpen3(true);
        }
      }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);

        setCurrentImg2(0);
        setIsViewerOpen2(false);

        setCurrentImg3(0);
        setIsViewerOpen3(false);
      };
    return (
        <> 
            <h2 className="light title">More Fun Projects</h2>
            <p className="light">Here is an assortment of some fun projects I've had the pleasure to work on.</p>
            <div>
                {MISC_WORK.etc.map((src, indexOne) => (
                    <div key={indexOne} className="misc">
                        <p className="light">{src.description}</p>
                        <div className="image-top-container misc-two">
                            {src.photos.map((srcPhoto, index) => (
                                <ProgressiveImg 
                                src={ srcPhoto }
                                onClick={ () => openImageViewer(src.name, index) }
                                className="max-size max-height"
                                key={ index }
                                style={{ margin: '2px' }}
                                alt=''
                                />
                            ))}   
                        </div>
                    </div>
                ))}
            </div>
            {isViewerOpen && (
                <ImageViewer
                src={MISC_WORK.etc[0].photos}
                currentIndex={ currentImage }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                />
                )}
            {isViewerOpen2 && (
                <ImageViewer
                src={MISC_WORK.etc[1].photos}
                currentIndex={ currentImg2 }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                />
                )}
            {isViewerOpen3 && (
                <ImageViewer
                src={MISC_WORK.etc[2].photos}
                currentIndex={ currentImg3 }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                />
                )}
        </>
    )
}