import { useState, useCallback } from "react";
import UseDocumentTitle from "../functions/useDocumentTitle";
import TITLE from "../images/techinfashion/name.webp"
import { TECH_IN_FASHION } from "../images/techinfashion/imageObjects";
import ImageViewer from 'react-simple-image-viewer';
import ProgressiveImg from "../components/progressiveimg/ProgressiveImg";


export default function TechInFashion() {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
      }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
      };

    return (
        <> 
            <UseDocumentTitle title="Tech In Fashion" />
            <div className="page-header">
                <ProgressiveImg src={TITLE} alt="Tech In Fashion Title" />
            </div>
            <div className="image-top-container melting-concept">
            {TECH_IN_FASHION.map((src, index) => (
                <ProgressiveImg 
                src={ src }
                onClick={ () => openImageViewer(index) }
                className="rounded-img"
                key={ index }
                style={{ margin: '2px' }}
                alt=''
                />
            ))}
            </div>
            {isViewerOpen && (
                <ImageViewer
                src={TECH_IN_FASHION}
                currentIndex={ currentImage }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                />
            )}
        </>
    )
}