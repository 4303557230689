import { useState, useCallback } from "react";
import UseDocumentTitle from "../functions/useDocumentTitle";
import TITLE from "../images/illustrations/name.webp"
import { ILLUSTRATIONS } from "../images/illustrations/imageObject";
import ImageViewer from 'react-simple-image-viewer';
import ProgressiveImg from "../components/progressiveimg/ProgressiveImg";

const PHOTOS = [
    ILLUSTRATIONS[0].photo, ILLUSTRATIONS[1].photo, ILLUSTRATIONS[2].photo
]

export default function Illustrations() {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
      }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
      };
    return (
        <> 
            <UseDocumentTitle title="Illustrations" />
            <div className="page-header">
                <ProgressiveImg src={TITLE} alt="Illustrations Title" />
            </div>
            <div className="image-top-container print-selection">
                {ILLUSTRATIONS.map((src, index) => (
                    <div key={index}>
                        <h2 className="light-header">{src.title}</h2>
                        <ProgressiveImg 
                        src={ src.photo }
                        onClick={ () => openImageViewer(index) }
                        className="max-size"
                        key={ index }
                        style={{ margin: '2px' }}
                        alt=''
                        />
                    </div>
                ))}
            </div>
            {isViewerOpen && (
                <ImageViewer
                src={PHOTOS}
                currentIndex={ currentImage }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                />
            )}
        </>
    )
}