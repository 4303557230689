import React, { useEffect, useState } from 'react';
import { push as Menu } from 'react-burger-menu';
import { useLocation, NavLink } from 'react-router-dom';
import { routes } from '../../routes/routes';
import PLACEHOLDER from "../../images/placeholderImg.png";
import Nav from 'react-bootstrap/Nav';
import 'bootstrap/dist/css/bootstrap.min.css';
import LOGO from "../../images/logo.webp";
import ProgressiveImg from '../progressiveimg/ProgressiveImg';

export default function Navigation() {
    const location = useLocation();
    const [windowDimension, setWindowDimension] = useState(null);

    useEffect(() => {
      setWindowDimension(window.innerWidth);
    }, []);
  
    useEffect(() => {
      function handleResize() {
        setWindowDimension(window.innerWidth);
      }
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    const isMobile = windowDimension <= 768;
    return (
        <>
            {
                isMobile ? (
                    <Menu pageWrapId={"web-content"} className="sticky" right>
                        <Nav.Item>
                            <NavLink to={routes[1].path}>{routes[1].name}</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to={routes[2].path}>{routes[2].name}</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to={routes[3].path}>{routes[3].name}</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to={routes[4].path}>{routes[4].name}</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to={routes[7].path}>{routes[7].name}</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to={routes[5].path}>{routes[5].name}</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to={routes[6].path}>{routes[6].name}</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <a href="https://silvermistdesign.square.site/">Shop</a>
                        </Nav.Item>
                    </Menu>
                ) : (
                    <Nav defaultActiveKey="/" activeKey={location.pathname} navbar variant='pills' justify>
                        <Nav.Item>
                            <NavLink to={routes[1].path}>{routes[1].name}</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to={routes[2].path}>{routes[2].name}</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to={routes[3].path}>{routes[3].name}</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to={routes[4].path}>{routes[4].name}</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to="/">
                                <ProgressiveImg src={LOGO} id="navLogo" alt="logo" placeholderSrc={PLACEHOLDER}/>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to={routes[7].path}>{routes[7].name}</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to={routes[5].path}>{routes[5].name}</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to={routes[6].path}>{routes[6].name}</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <a href="https://silvermistdesign.square.site/">Shop</a>
                        </Nav.Item>
                    </Nav>
                )
            }
        </>
    )
}