import React , { useRef }from "react"
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";
import "../../styles/main.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import SocialMedia from "../socialmedia/SocialMedia";


export default function ContactForm() {
  const form = useRef();

    const USER_ID = process.env.REACT_APP_USER_ID;
    const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
    const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;

    const handleOnSubmit = (e) => {
      e.preventDefault();
      console.log(form.current)
      emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_ID)
        .then((result) => {
          console.log(result.text);
          Swal.fire({
            icon: "success",
            title: "Message Sent Successfully",
          })
          Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
          )
          Array.from(document.querySelectorAll('textarea')).forEach(
            textarea => (textarea.value = '')
          )
        }, (error) => {
          console.log(error.text);
          Swal.fire({
            icon: "error",
            title: "Oops, something went wrong",
            text: error.text,
          })
        });
    };

    return (
      <>
        <div id="contact-box">
          <h2 className="h2-underline"><b>Info</b></h2>
            <div>
              <span>Email: <a href = "mailto: mirandaklingdesign@gmail.com">mirandaklingdesign@gmail.com</a></span>
              <SocialMedia />
            </div>
            <Form id="contact-form"  ref={form} onSubmit={handleOnSubmit}>
                <Form.Group className="form-floating mb-3">
                    <Form.Control column="lg" lg={2} type="text" className="form-control" id="floatingInputName" placeholder="Name" name="from_name" required/>
                    <Form.Label size="lg" htmlFor="floatingInputName">Name *</Form.Label>
                </Form.Group>
                <Form.Group className="form-floating mb-3">
                    <Form.Control column="lg" type="email" className="form-control" id="floatingInputEmail" placeholder="Email Address" name="from_email" required/>
                    <Form.Label size="lg" htmlFor="floatingInputEmail">Email address *</Form.Label>
                </Form.Group>
                <Form.Group className="form-floating mb-3">
                    <Form.Control column="lg" type="text" className="form-control" id="floatingInputSubject" placeholder="Subject" name="subject"/>
                    <Form.Label size="lg" htmlFor="floatingInputSubject">Subject</Form.Label>
                </Form.Group>
                <Form.Group className="form-floating">
                    <Form.Control column="lg" as="textarea" row={3} className="form-control" id="floatingTextarea" placeholder="Message" name="message"/>
                    <Form.Label size="lg" htmlFor="floatingTextarea">Message</Form.Label>
                </Form.Group>
                <button type="submit" className="btn btn-outline-light ">Submit</button>
            </Form> 
        </div>
      </>
    )
}